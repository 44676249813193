<template>
  <div class="container-fuild">
    <div class="card col-12 col-sm-11 col-xl-10">
      <div class="card-body">
        <div class="mb-3">
          <h3>
            รายงานออเดอร์
          </h3>
        </div>
        <div class="row ">
          <div class="col-12 col-sm-6 col-xl-5 text-center mb-2">
            <div class="mb-1">
              <b-img
                thumbnail
                fluid
                :src="datas.pic"
                alt="Image 1"
                style="max-width:170px;width:100%"
                @error="setAltImg"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xl-7 ">
            <table>
              <thead>
                <tr>
                  <th style="min-width:85px; width:30%;" />
                  <th style="width:70%;" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="pr-1">
                    <p>รหัสลูกค้า</p>
                  </td>
                  <td colspan="2">
                    <p>{{ datas.code }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="pr-1">
                    <p>ชื่อ</p>
                  </td>
                  <td colspan="2">
                    <p>{{ datas.firstName }} &nbsp;{{ datas.lastName }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="pr-1">
                    <p>
                      เบอร์โทร
                    </p>
                  </td>
                  <td>
                    <p>{{ datas.telephoneNumber }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="pr-1">
                    <p>
                      ประเภท
                    </p>
                  </td>
                  <td>
                    <p>{{ datas.type === 'U'?'ลูกค้าทั่วไป': 'ตัวแทน' }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="pr-1">
                    <p>
                      รวมราคา
                    </p>
                  </td>
                  <td>
                    <p>{{ sumTotal | toCurrencyBath }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row ">
          <div class="col-12 col-sm-4 col-xl-3">
            <b-form-group
              label="เริ่มต้น"
              label-for="vi-first-name"
            >
              <DatePicker
                ref="updateDate"
                :date="inputs.dateStart"
                :type="'start'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              />
            </b-form-group>
          </div>
          <div
            class="col-12 col-sm-4 col-xl-3"
          >
            <b-form-group
              label="
            สิ้นสุด"
              label-for="vi-first-name"
            >
              <DatePicker
                :date="inputs.dateEnd"
                :type="'end'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-sm-4 align-self-center">
            <b-form-checkbox
              v-model="inputs.checkedList"
              checked="true"
              name="check-button"
              switch
              inline
            >
              ทั้งหมด
            </b-form-checkbox>

          </div>
        </div>
        <div class="row col-12 mt-1">
          <b-table
            striped
            hover
            responsive
            class="type-relative"
            :per-page="inputsnode.perPage"
            :current-page="inputsnode.currentPage"
            :items="listOrder"
            :fields="fields"
            :filter="searchTerm"
            show-empty
            @filtered="onFiltered"
          >
            <template #cell(date)="data">
              {{ data.item.item.updatedAt | formatDate }}
            </template>
            <template #cell(price)="data">
              {{ data.item.item.totalPrice.$numberDecimal | toCurrency }}
            </template>
            <template #cell(status)="data">
              <p :class="data.item.item.status === 'i'?'text-primary':data.item.item.status === 'c'?'text-danger':data.item.item.status === 's'?'text-warning':data.item.item.status === 'a'?'text-info':'text-success'">
                {{ data.item.item.status === 'i'?'รอการจัดการ':data.item.item.status === 'c'?'ยกเลิก':data.item.item.status === 's'?'จัดส่ง':data.item.item.status === 'a'?'รอการชำระ':'อนุมัติ' }}
              </p>
            </template>
            <template #cell(button)="data">
              <div class="d-grid d-md-block">
                <button
                  class="btn btn-primary mr-1"
                  type="button"
                  @click="openModal(data.item.index)"
                >
                  รายละเอียด
                </button>
              </div>
            </template>
            <template #empty>
              <div class="col-md-12 text-center m-1">
                <h4>ไม่พบข้อมูลการขาย</h4>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="col-md-12 text-center m-1">
                <h4>ไม่พบข้อมูลการขาย</h4>
              </div>
            </template>
          </b-table>
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0 mt-2">
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="inputsnode.perPage"
                size="sm"
                inline
                :options="inputsnode.pageOptions"
              />
            </b-form-group>

            <div>
              <b-pagination
                v-model="inputsnode.currentPage"
                :total-rows="inputsnode.totalRows"
                :per-page="inputsnode.perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>

        </div>
        <div class="mb-1 d-flex justify-content-center">
          <b-button
            variant="warning"
            class="mr-1"
            style="    width: 100px;"
            @click="onClickReturnPage()"
          >
            ย้อนกลับ
          </b-button>

        </div>
      </div>

    </div>
    <b-modal
      v-model="IsopenModal"
      hide-footer
      header-bg-variant="primary"
      class="colorF"
      centered
      size="lg"
      :title="textOrder"
    >
      <div
        v-if="listOrder.length > 0"
        class="mt-1"
      >
        <h5 class="mb-2">
          รายการออเดอร์
        </h5>
        <b-table
          :sticky-header="true"
          :no-border-collapse="false"
          responsive
          :items="listOrder[indexDetail].item.listProduct"
          :fields="detailorder"
          class="mb-0"
        >
          <template #cell(code)="data">
            <p>{{ data.item.id.code }}</p>
          </template>
          <template #cell(name)="data">
            <p>{{ data.item.id.name }}</p>
          </template>
          <template #cell(amount)="data">
            <p>{{ data.item.amount.$numberDecimal | toAmount }}</p>
          </template>
          <template #cell(price)="data">
            <p>{{ data.item.price.$numberDecimal | toCurrency }}</p>
          </template>

        </b-table>

      </div>
      <div class="mt-2 d-flex justify-content-center">
        <b-button
          variant="danger"
          class="mr-1"
          style="    width: 100px;"
          @click="() => {IsopenModal = false}"
        >
          ปิด
        </b-button>
      </div>

    </b-modal>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import {
  BImg, BRow, BCol, BTable, BFormGroup, BFormSelect, BPagination, BModal, BButton, BCardBody, BFormInput, BFormCheckbox
  , BForm
} from 'bootstrap-vue'
import moment from 'moment'
import router from '@/router'
import PlaceHolder from '@/assets/images/production/img_default.png'
import DatePicker from '@/views/components/production/datePicker.vue'

export default defineComponent({
  name: 'customer_order',
  components: {
    BImg,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BModal,
    BButton,
    BCardBody,
    BFormInput,
    BFormCheckbox,
    BForm,
    DatePicker
  },
  watch: {
    'inputs.checkedList': {
      handler (val) {
        this.getListOrder()
      }
    },
    'inputs.dateStart': {
      handler (val) {
        this.getListOrder()
      }
    },
    'inputs.dateEnd': {
      handler (val) {
        this.getListOrder()
      }
    }
  },
  methods: {
    onClickReturnPage () {
      if (this.$route.params.start !== undefined) router.push('/dashboard'); else if (this.datas.type === 'U') router.push('/customer/list'); else if (this.datas.type === 'V') router.push('/agent/list')
    },
    openModal (index) {
      this.indexDetail = index
      this.IsopenModal = true
      this.currentPage = 0
      this.textOrder = `No.${this.listOrder[this.indexDetail].item.code}`
    },
    onFiltered () {

    },
    onSetDatePicker (data) {
      if (data.type === 'start') this.inputs.dateStart = data.val; else this.inputs.dateEnd = data.val
    },
    async onLoad () {
      await this.lander()
      this.getCustomer()
      this.getListOrder()
    },
    getCustomer () {
      this.$store.dispatch('customer/getCustomerByCode', { id: this.$route.params.code }).then(res => {
        if (res !== null) {
          this.datas.code = res.code
          this.datas.firstName = res.firstName
          this.datas.lastName = res.lastName
          this.datas.type = res.type
          this.datas.telephoneNumber = res.telephoneNumber
          this.datas.pic = this.$baseURL + res.pic
        }
      })
    },
    getListOrder () {
      if (!this.loadLoad) return
      this.$store.dispatch('report/getReportOrderWareCustomer', { customerId: this.$route.params.code, startDate: this.inputs.checkedList ? '' : this.inputs.dateStart, endDate: this.inputs.checkedList ? '' : this.inputs.dateEnd }).then(res => {
        if (res.status === 200) {
          this.listOrder = []
          this.sumTotal = 0
          res.data.item.forEach((data, i) => {
            if (data.status !== 'c' && data.status !== 'i') this.sumTotal += parseFloat(data.totalPrice.$numberDecimal)
            this.listOrder.push({
              item: data,
              index: i
            })
          })

          this.inputsnode.totalRows = this.listOrder.length
        }
      })
    },
    setAltImg (e) {
      e.target.src = PlaceHolder
    },
    async lander () {
      if (this.$route.params.start !== undefined) {
        this.inputs.dateStart = this.$route.params.start
        this.inputs.dateEnd = this.$route.params.end
      } else {
        this.inputs.checkedList = true
      }
      this.loadLoad = true
    }
  },
  created () {
    this.onLoad()
  },
  data () {
    return {
      indexDetail: 0,
      textOrder: '',
      loadLoad: false,
      IsopenModal: false,
      sumTotal: 0,
      datas: {
        code: '',
        firstName: '',
        lastName: '',
        telephoneNumber: '',
        pic: '',
        type: ''
      },
      listOrder: [],
      searchTerm: '',
      fields: [
        {
          key: 'item.code', label: 'รหัสออเดอร์', thStyle: 'min-width: 100px', sortable: true
        },
        {
          key: 'date', label: 'วันที่-เวลา', thStyle: 'min-width: 240px'
        },
        {
          key: 'price', label: 'ราคา', thStyle: 'min-width: 130px', tdClass: 'text-right', thClass: 'text-right'
        },
        {
          key: 'status', label: 'สถานะ', thStyle: 'min-width: 140px', tdClass: 'text-right', thClass: 'text-right'
        },
        { key: 'button', label: '', thStyle: 'min-width: 190px', tdClass: 'text-center', thClass: 'text-center' }

      ],
      detailorder: [
        {
          key: 'code', label: 'รหัสสินค้า', thStyle: 'width: 30%   ;min-width: 170px;', sortable: true
        }, {
          key: 'name', label: 'ชื่อสินค้า', thStyle: 'width: 40% ;min-width: 200px;'
        }, {
          key: 'amount', label: 'จำนวน', thStyle: 'width: 15% ;min-width: 80px;'
        }, {
          key: 'price', label: 'ราคา', thStyle: 'width: 15% ;min-width: 80px;'
        }
      ],
      inputs: {
        dateStart: moment().format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD'),
        checkedList: false
      },
      inputsnode: {
        perPage: 5,
        pageOptions: [5, 10, 15],
        totalRows: 0,
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 0,
        endItem: 10
      }

    }
  }
})
</script>
<style>
.b-table-sticky-header {
    max-height: 300px !important;
}

.modal-title {
        color: white !important;
}
</style>
